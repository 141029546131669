.semi-button  {
    font-size: 60px;
    width: 40%;
    display: inline-block;
    text-align: center;
    border: 1px solid;
    padding: 10px 10px 10px 10px;
    margin: 5px;
    background-color: var(--col-1);
}

.subtitle{
    font-size: 20px;
    margin-bottom: 10px;
}