.input-box {
    width: 120px;
    font-size: 18px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.button {
    padding: 11px;
    margin-left: 30px;
    position: relative;
    top: 1px;
}