body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #4cc1f3;
  cursor: pointer;
}

a:hover {
  color: #3383a4;
}

button {
  padding: 15px;
  color: white;
  background-color: #3383a4;
  border: none;
  font-size: 20px;
}

button:active {
  background-color: #1c556c;
}