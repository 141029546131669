.plot{
    font-size: 22px;
    background-color: var(--col-1);
    border: 1px solid;
    padding: 10px 15px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subtitle{
    font-size: 20px;
    margin-bottom: 10px;
}

.plot > span > div {
    font-size: 12px;
}

.plot span{
    text-align: center;
    padding-left: 25px;
    display: inline-block;
}

.plot .code{
    padding-left: 0;
    text-align: left;
    flex-grow: 1;
    display: inline-block;
}

.location {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: -20px;
}