:root{
  --colbg: #022a2a;
  --coltextmenuitem: white;
  --col-1: #182747;
  --col-2: #562B08;
  --col-3: #27641d;
  --col-4: #282828;
}

body {
  background: #022a2a;
  color: white;
  font-size: 18px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
  margin-top: -16px;
  font-weight: normal;
}

.App {
  padding-left: 12px;
  padding-right: 12px;
}

html, #root, body, .App, .menu{
  height: 100%;
}

.header{
  font-size: 35pt;
  text-align: center;
}

.col-pollen{
  background-color: var(--col-1);
}

.col-emasculeren{
  background-color: var(--col-2);
}

.col-kruisen{
  background-color: var(--col-3);
}

.col-oogsten{
  background-color: var(--col-4);
}

.footer {
  text-align: center;
  margin-top: 15px;
  padding-bottom: 5px;
  font-size: 12px;
}