.bar {
    width: 100%;
    box-sizing: border-box;
    background: #FFCC00;
    color: black;
    padding: 10px 0px;
    text-align: center;
}

:global .connected {
    display: none;
}

:global .disconnected {
    background: #FF8800;
}

:global .test {
    background: #00FF88;
}