.menu-item img {
    vertical-align: middle;
    height: 100%;
  }
  
.menu-item{
    display: flex;
    position:relative;
    align-items:center;
    height: 100px;
    width: 94%;
    margin-left: 3%;
    color: var(--coltextmenuitem); 
    background: var(--colmenuitem);
    /* padding: 5px 10px 5px 10px; */
    /* margin: 0; */
    margin-top: 10px;
    border: 1px solid;
    cursor: pointer;
    /* border-width: thin; */
}

.menu-item:hover {
   border-color: #3383a4;
}

.menu-item span > div {
    font-size: 14px;
}

a { 
    text-decoration: none;
}

.title{
    text-align: left;
    padding-left: 20px;
    color: white;
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
    max-width: 400px;
    padding-right: 10px;
}
